import Api from '@/services/Api'
import axios from 'axios'
import config from 'src/config'

export default {
  getReminders () {
    return Api().get('/model/reminder?include=true')
  },
  createReminder(data, group, all) {
    return Api().post('/model/reminder?group='+group+'&all='+all, data)
  },
  getReminder (id) {
    return Api().get('/model/reminder/'+id+'?include=true')
  },
  updateReminder(id, data) {
    return Api().patch('/model/reminder/'+id, data)
  },
  getCompanyReminder (token) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).get('/model/reminder/company-reminder?include=true')
  },
  uploadItems (data, token) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).patch('/model/reminder/items', data)
  },
  uploadItemsNonRilevante (data, token) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).patch(`/model/reminder/items/non-rilevante`,data)
  }
}