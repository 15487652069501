<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Gestione Clienti</h4>
    </div>

    <div class="col-12">
      <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
      </b-overlay>
      <b-modal ref="my-modal" hide-footer title="Eliminazione Cliente">
        <div class="d-block text-center">
          <h3>Sicuro di voler eliminare questo utente?</h3>
        </div>
        <b-button class="mt-3" variant="outline-danger" style="backgroud-color: red;" block @click="hideModal">Chiudi</b-button>
        <b-button class="mt-2" variant="outline-warning" style="backgroud-color: green;" block @click="deleteUser">Elimina</b-button>
      </b-modal>
      <card>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per pagina">
              <el-option
                class="select-default"
                v-for="item in perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <el-input type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="Cerca cliente"
                      v-model="searchQuery"
                      aria-controls="datatables"/>
          </div>
          <div class="col-sm-12" v-if="hasLoaded">
            <el-table stripe
                      style="width: 100%;"
                      :data="queriedData"
                      border>
              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth/2"
                               :prop="column.prop"
                               :label="column.label">
              </el-table-column>
              <el-table-column
                :min-width="120"
                fixed="right"
                label="Azioni">
                <template slot-scope="props">
                  <!-- <a v-tooltip.top-center="'Like'" class="btn-info btn-simple btn-link"
                     @click="handleLike(props.$index, props.row)">
                    <i class="fa fa-heart"></i></a> -->
                  <a v-tooltip.top-center="'Modifica'" class="btn-warning btn-simple btn-link"
                     @click="handleEdit(props.$index, props.row)"><i
                    class="fa fa-edit"></i></a>
                  <a v-tooltip.top-center="'Elimina'" class="btn-danger btn-simple btn-link"
                     @click="handleDelete(props.$index, props.row)"><i class="fa fa-times"></i></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">Da {{from + 1}} a {{to}} di {{total}} clienti</p>
          </div>
          <l-pagination class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import {Pagination as LPagination} from 'src/components/index'
  import users from './users'
  import Fuse from 'fuse.js'
  import CompanyService from 'src/services/CompanyService'
  import ReminderService from 'src/services/ReminderService'
  import Vue from 'vue'
  import { ModalPlugin } from 'bootstrap-vue'
  Vue.use(ModalPlugin)
  import { ButtonPlugin } from 'bootstrap-vue'
  Vue.use(ButtonPlugin)

  export default {
    components: {
      LPagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        /*let result = this.tableData
        
        if (this.searchQuery !== '' && this.searchQuery.trim().length>4) {          
          result = this.fuseSearch.search(this.searchQuery.trim())
          
          const ids = result.map(x => x.item.id)
          result = this.tableData.filter(x => ids.includes(x.id))
              
          this.pagination.total = result.length
          
        }
        return result*/
      if (!this.searchQuery) {
        return this.pagedData
      }
      const result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (const key of this.propsToSearch) {
            
            const rowValue = row[key].toString().toLowerCase()
            var res = this.searchQuery.split(" ")
            res.map(token => {
              if (rowValue.includes && rowValue.includes(token.toLowerCase())) {
              isIncluded = true
              }
            })
            
          }
          return isIncluded
        })
      return result.slice(this.from, this.to)

        
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      perPageOptions: function () {
        let a = this.tableData.length / 5
        let b = this.tableData.length % 5
        if (a >= 10 || a >= 9 && b > 0) {
          this.pagination.perPageOptions = [5, 10, 25, 50]
          return [5, 10, 25, 50]
        } else if (a >= 5 || a >= 4 && b > 0) {
          this.pagination.perPageOptions = [5, 10, 25]
          return [5, 10, 25]
        } else if (a >= 2 || a >= 1 && b > 0) {
          this.pagination.perPageOptions = [5, 10]
          return [5, 10]
        } else {
          this.pagination.perPageOptions = [5]
          return [5]
        }
      }
    },
    data () {
      return {
        hasLoaded: false,
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['first_name', 'last_name','ragione_sociale'],
        tableColumns: [
          {
            prop: 'ragione_sociale',
            label: 'Ragione Sociale',
            minWidth: 250
          },
          {
            prop: 'partita_iva',
            label: 'Partita Iva',
            minWidth: 250
          },
          {
            prop: 'first_name',
            label: 'Nome',
            minWidth: 200
          },
          {
            prop: 'last_name',
            label: 'Cognome',
            minWidth: 250
          },
          {
             prop: 'email',
             label: 'Email',
             minWidth: 250
           },
          {
            prop: 'groups',
            label: 'Gruppi',
            minWidth: 250
          }
        ],
        tableData: [],
        fuseSearch: null,
        delete_company_id: null,
        error: null
      }
    },
    methods: {
      handleLike (index, row) {
        alert(`Your want to like ${row.first_name}`)
      },
      handleEdit (index, row) {
        this.$router.push({
          path: '/admin/edit-cliente/'+row.id
        })
      },
      handleDelete (index, row) {
        // let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        // if (indexToDelete >= 0) {
        //   this.tableData.splice(indexToDelete, 1)
        // }
        this.delete_company_id = row.id
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.delete_company_id = null
        this.$refs['my-modal'].hide()
      },
      async deleteUser() {
        try {
          this.hasLoaded = false
          const ris = await CompanyService.deleteCompany(this.delete_company_id)
          console.log('ris', ris)
          // await ReminderService.updateReminder(this.delete_company_id, {
          //   canceled: true
          // })
          await this.fetchData()
        } catch(err) {
          console.log('err', err)
          this.error = err
        } finally {
          this.$refs['my-modal'].hide()
          this.hasLoaded = true
        }
      },
      async fetchData() {
        try {
          const companies = await CompanyService.getCompanies()
          this.tableData = companies.data.map(el => {
            let obj = {}
            obj.id = el.id
            obj.first_name = el.first_name
            obj.last_name = el.last_name
            obj.email = el.metas.contatti.email
            obj.groups = el.groups.length > 0 ? el.groups.map(el => el.label).join(", ") : '-'
            obj.ragione_sociale = el.ragione_sociale ? el.ragione_sociale : '-'
            obj.partita_iva = el.partita_iva ? el.partita_iva : '-'
            return obj
          })
          if(companies){
            const options = {
             includeScore:true,
             minMatchCharLength: 6,
             includeMatches:true,
             threshold:0.6,
             keys:["first_name","last_name","email"]
           }

            this.fuseSearch = new Fuse(this.tableData, options)
          }          
        } catch(err) {
          console.log('err', err)
          this.error = err
        } finally {
          this.hasLoaded = true
        }
      }
    },
    async mounted () {
      this.fetchData()      
    }
  }
</script>
<style>
</style>
